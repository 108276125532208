@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* //Header************************************************************************ */

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #eee;
}
html {
  background-color: #eee !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
a {
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none !important;
}
.root-style {
  background: #0b7dda;
}
.sidebar-classes {
  position: relative;
  height: 100%;
  overflow: hidden auto;
  z-index: 3;
  background-color: rgb(255, 255, 255);
}
.container-dash {
  min-height: 100%;
}
.outlet-container {
  overflow: auto;
  min-height: 100vh;
  padding-top: 100px;
  margin-left: 220px;
  padding-left: 30px;
  padding-right: 10px;
  background-color: #eee;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.header-section {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 9999;
  padding: 6px 0px;
  align-items: center;
  background: #4e606c;
  color: white;
  border-bottom: 3px solid cadetblue;
}
.vertical-line {
  width: 2px;
  background: white;
}
.logo-section {
  display: flex;
  grid-gap: 52px;
  gap: 52px;
  margin-left: 13px;
}
.page-name {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  padding-top: 2px;
}
.logo-section img {
  width: 140px;
}
.search-section {
  display: flex;
  align-items: center;
}

form.example input[type="text"] {
  padding: 4px;
  font-size: 17px;
  /* border: 1px solid grey; */
  border: none;
  float: left;
  width: 75%;
  background: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

form.example button {
  float: left;
  width: 20%;
  padding: 4px;
  /* background: #2196F3;
  color: white; */
  font-size: 17px;
  /* border: 1px solid grey;
  border-left: none; */
  border: none;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
}

form.example button:hover {
  background: #0b7dda;
}

form.example::after {
  content: "";
  clear: both;
  display: table;
}

#dropdown-login {
  background: none !important;
  box-shadow: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

/* //Header End************************************************************************ */

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  top: 74px;
  width: 220px;
  height: 100%;
  overflow-y: auto;
  background: #eee;
  border-right: 0.5px solid #80808087;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  padding-bottom: 90px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

/* Sidebar Styles */

.sidebar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.Collapsible {
  background: white;
  padding: 10px 10px;
  border-radius: 15px;
  margin: 0px 10px;
  margin-top: 10px;
}
.Collapsible span {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  grid-gap: 14px;
  gap: 14px;
  color: #4d606b;
}
.Collapsible span:hover {
  color: #fd7e14;
}
.Collapsible__trigger.is-open svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.Collapsible__trigger.is-open .fa-chevron-right {
  -webkit-transform: rotate(90deg) !important;
          transform: rotate(90deg) !important;
}
.Collapsible__contentOuter {
  height: auto;
  transition: height 150ms linear 0s !important;
  overflow: hidden;
}

.Collapsible__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fa-solid {
  color: #e6c693;
}
.trig-span {
  flex-grow: 6;
}
.sidebar-list {
  font-size: 12px;
  list-style-type: none;
  margin-top: 7px;
  margin-bottom: 0px;
}
.sidebar-list li {
  cursor: pointer;
  margin-top: 10px;
  color: grey;
}
.sidebar-list li:hover {
  color: #fd7e14;
}
.home-btn {
  margin-left: 10px;
  margin-top: 33px;
  background: #fff;
  margin-right: 10px;
  border-radius: 15px;
  padding: 8px 11px;
}
.home-btn:hover {
  color: #fd7e14;
  cursor: pointer;
}
.home-btn span {
  font-size: 13px;
  font-weight: 700;
  color: #4d606b;
  margin-left: 6px;
}
.fa-home {
  color: #e6c693;
}

/* //Table**************************************************************** */

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
}
.export-btn {
  background: #f7901e;
  color: white;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 6px 12px;
  white-space: nowrap;
}
.export-btn:hover {
  background-color: #ffa542;
}

.button-group {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

thead .sorting:before {
  color: #f7901e !important;
}
thead .sorting:after {
  color: #f7901e !important;
}
.pagination .page-item.active .page-link {
  background-color: #f7901e !important;
}
td {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
}
.employee {
  font-weight: 700;
}

.badge-success {
  background-color: #d6f0e0 !important;
  color: #0d6832 !important;
}
tr td:first-child {
  width: 50px;
}
.round-border {
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.big-box {
  margin-bottom: 40px;
}
th {
  font-weight: 500 !important;
}
tr {
  text-align: center;
}
td {
  vertical-align: middle;
}

/* //Breadcrumb*********************************************************** */
.crumb-heading {
  font-size: 27px;
  font-weight: 700px;
  font-weight: 600;
}
.breadcrumb-item a {
  color: black;
  font-size: 15px;
}
.breadcrumb-item a:hover {
  color: #fd7e14;
}
.active-badge {
  background-color: #00c85124 !important;
  color: rgb(34, 154, 22) !important;
  border-radius: 10px !important;
  font-size: 13px;
  padding: 2px 8px;
  font-weight: 600;
}
.deactive-badge {
  background-color: #c82a0024 !important;
  color: rgb(154 22 22) !important;
  border-radius: 10px !important;
  font-size: 13px;
  padding: 2px 8px;
  font-weight: 600;
}

.edit-btn {
  background: transparent;
  margin-right: 10px;
  border: none;
}
.edit-btn:hover {
  color: #f7901e;
}
.delete-btn:hover {
  color: #f7901e;
}
.delete-btn {
  background: transparent;
  border: none;
}
.upload-btn {
  background: transparent;
  border: none;
  font-size: 18px;
}
.upload-btn .fa-upload:hover {
  color: #f7901e !important;
}

/* //form**************************************************************** */
.form-label {
  font-size: 14px;
  font-weight: 500;
}

/* //loginpage******************************************************************* */

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 15px;
  width: 400px;
  opacity: 0.9;
  padding: 19px 0px;
}
.center h1 {
  text-align: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #f3c932;
  color: #2a2929;
}
.center form {
  padding: 0 40px;
  box-sizing: border-box;
}
form .txt_field {
  position: relative;
  border-bottom: 2px solid #adadad;
  margin: 30px 0;
}
.txt_field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}
.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.txt_field span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0;
  height: 2px;
  background: #2691d9;
  transition: 0.5s;
}
.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
  top: -5px;
  color: #2691d9;
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
  width: 100%;
}
.pass {
  margin: -5px 0 20px 5px;
  color: #666666;
  cursor: pointer;
}
.pass:hover {
  text-decoration: underline;
}
input[type="submit"] {
  width: 100%;
  height: 50px;
  border-radius: 20px;
  border: 1px solid;
  background: #2691d9;
  color: #e9f4fb;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}
input[type="submit"]:hover {
  border-color: #2691d9;
  transition: 0.5s;
}
.singup_link {
  margin: 30px 0;
  text-align: center;
  font-size: 16px;
  color: #666666;
}
.singup_link a {
  color: #2691d9;
  text-decoration: none;
}
.singup_link a:hover {
  text-decoration: underline;
}

.login-fluid {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  height: 100vh;
  overflow: hidden;
  background-blend-mode: multiply;
  background-color: #00000066;
}
.form-check {
  margin-bottom: 15px !important;
}
.form-check label {
  font-weight: 500;
}

/* //Dashboard *************************************************** */
.users-card {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 30px 15px;
  border-radius: 5px;
  align-items: baseline;
  margin-top: 30px;
}
.users-card span {
  font-size: 17px;
  font-weight: 600;
}
.users-card p {
  font-size: 25px;
  font-weight: 600;
}
.total-user {
  background-image: linear-gradient(
    -20deg,
    #2b5876 0%,
    #4e4376 100%
  ) !important;
}
.total-project {
  background-image: radial-gradient(
    circle 248px at center,
    #16d9e3 0%,
    #30c7ec 47%,
    #46aef7 100%
  ) !important;
}
.active-user {
  background-image: linear-gradient(
    to top,
    #0ba360 0%,
    #3cba92 100%
  ) !important;
}

.welcome-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #a9d0fb;
  margin-top: 40px;
  border-radius: 5px;
}
.welcome-text {
  margin-left: 30px;
}
.welcome-text h3 {
  font-weight: 600;
}
strong {
  font-weight: 600 !important;
}
.welcome-image img {
  width: 165px;
}

.react-datalist-input__textbox {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-datalist-input__label {
  font-size: 14px;
  font-weight: 500;
}

.btn-group {
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.form-select {
  margin-bottom: 10px !important;
}

.export-list {
  background: transparent;
  border: none;
  color: white;
}

@media screen and (min-width: 1400px) {
  .dashboard-container {
    max-width: 1580px !important;
  }
  .heading {
    max-width: 1800px !important;
  }
}

tbody + thead {
  display: none;
}

/* //templates*********************************************************** */

.color-border {
  width: 10px;
}

.reciept {
  border: 0.5px solid #e3e3e3;
  padding: 10px 10px;
  /* border-left: 1px solid; */
  margin: 10px 0px;
}
.add-reciept {
  background: white;
  border: 0.5px solid gray;
  padding: 3px 5px;
}

.offcanvas.offcanvas-end {
  z-index: 9999;
  width: 530px !important;
}
.offcanvas-header {
  background-color: #1da586;
  color: white;
}
.provider p {
  font-size: 14px;
}
.save-btn {
  margin-top: 15px;
}
.save-customize {
  border: 1px solid #1da586;
  background: #1da586;
  color: white;
  padding: 5px 21px;
  margin-right: 20px;
}
.close-customize {
  border: 0.5px solid rgb(116, 116, 116);
  background-color: #fff;
  padding: 5px 21px;
  margin-right: 20px;
}

.pdf-btn {
  padding: 5px 15px;
  background: #db5757;
  color: white;
  border: none;
}
.custom-btn {
  margin-top: 24px !important;
  display: flex !important;
  align-items: baseline;
  grid-gap: 4px;
  gap: 4px;
}

.dropzone {
  padding: 20px;
  text-align: center;
  border: 1px solid grey;
  cursor: pointer;
}
.drop-para {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.fa-file-text {
  font-size: 58px;
}
.email-checkbox span {
  font-size: 13px;
  font-weight: 600;
}
.email-checkbox {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 5px;
}

.setting-collapse .Collapsible span {
  font-size: 20px;
  font-weight: 100;
  color: black;
  margin-top: 30px;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 8px;
}
.setting-collapse .Collapsible {
  margin: 0;
  padding: 0;
}

.setting-inputs {
  display: flex;
  grid-gap: 61px;
  gap: 61px;
  align-items: center;
}
/* toggle switch******************************************************************* */

input[switch] {
  display: none;
}
input[switch] + label {
  font-size: 1em;
  line-height: 1;
  width: 4.7rem;
  height: 1.5rem;
  background-color: #ddd;
  background-image: none;
  border-radius: 2rem;
  padding: 0.1666666667rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
  font-family: inherit;
  transition: all 0.1s ease-in-out;
}
input[switch] + label:before {
  /* Label */
  text-transform: uppercase;
  color: #b7b7b7;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-family: FontAwesome, inherit;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 1.22rem;
  position: absolute;
  right: 0.2166666667rem;
  margin: 0.2166666667rem;
  top: 0;
  text-align: center;
  min-width: 1.6666666667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}
input[switch] + label:after {
  /* Slider */
  content: "";
  position: absolute;
  left: 0.1666666667rem;
  background-color: #f7f7f7;
  box-shadow: none;
  border-radius: 2rem;
  height: 1.22rem;
  width: 1.22rem;
  transition: all 0.1s ease-in-out;
}
input[switch]:checked + label {
  background-color: lightblue;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.15),
    rgba(0, 0, 0, 0.2)
  );
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) inset;
}
input[switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 0.2166666667rem;
}
input[switch]:checked + label:after {
  left: 3.22rem;
  background-color: #f7f7f7;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
}

input[switch="bool"] + label {
  background-color: #ee6562;
}
input[switch="bool"] + label:before {
  color: #fff !important;
}
input[switch="bool"]:checked + label {
  background-color: #bce954;
}
input[switch="bool"]:checked + label:before {
  color: #fff !important;
}

input[switch="default"]:checked + label {
  background-color: #a2a2a2;
}
input[switch="default"]:checked + label:before {
  color: #fff !important;
}

input[switch="success"]:checked + label {
  background-color: #bce954;
}
input[switch="success"]:checked + label:before {
  color: #fff !important;
}

input[switch="warning"]:checked + label {
  background-color: gold;
}
input[switch="warning"]:checked + label:before {
  color: #fff !important;
}

.dropdown {
  display: inline-block;
}
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
  margin: 0;
}
.dropdown .dropbtn:hover {
  color: #a91c1c;
}

.dropdown-content {
  /* display: none; */
  font-size: 15px;
  right: 193px;
  top: 298px;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
@-webkit-keyframes fadeInUpmenu {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpmenu {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.dropdown-content a {
  float: none;
  color: black;
  padding: 8px 13px;
  font-size: 13px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  color: green !important;
}

.dropdown-content a:nth-child(8):hover {
  color: #cd1212 !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.area-content {
  position: fixed;
  z-index: 1;
  -webkit-animation-name: fadeInUpmenu;
  animation-name: fadeInUpmenu;
  -webkit-animation-duration: 120ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /* height: 200px; */
  background: white;
  width: 250px;
}
.fa-paper-plane:hover {
  color: steelblue !important;
}

/* //View Document Css ************************************************ */
.view-document {
  height: 100vh;
}
.document-list {
  overflow-x: scroll;
  height: 100vh;
}
.drag-info {
  display: flex;
  flex-direction: column;
}
.header-head {
  background-color: #1da586;
  color: #fff;
  padding: 10px;
}
.reciept-email {
  height: 50%;
}
.drag-field {
  height: 50%;
}
.Collapsible__contentInner img {
  width: 150px;
  height: 180px;
  margin-top: 20px;
}

.reciept-email span {
  padding: 15px 10px;
  display: block;
  width: 100%;
}
.reciept-email span:hover {
  background-color: #0ba3614f;
}
.drag-btn {
  width: 135px;
  height: 25px;
  font-size: 13px;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  align-items: baseline;
  border: 0.5px solid #1da586;
}
.drag-btn-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 9px;
  gap: 9px;
  padding: 10px;
}

.react-pdf__Page__textContent {
  background: white;
  display: none;
  color: black !important;
}
.top-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-light {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.light-nav-list li {
  list-style-type: none;
  padding: 11px 0px;
}
.light-nav-list {
  justify-content: space-around;
  background: #4e606c;
  margin: 10px 15px 0px 15px;
  border-radius: 10px;
  padding-left: 0;
}
.light-nav-list li span {
  color: #fff;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 10px;
  cursor: pointer;
}
.light-nav-list a {
  color: #fff;
}
.light-active a {
  color: #0033c4 !important;
}
.tab-name {
  display: inline-block;
  margin-top: 10px;
  background: #0033c4;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
.tab-container {
  /* padding: 15px; */
  background-color: white;
}
.job-table {
  border: 1px solid #dedede;
}
.job-table td {
  font-size: 12px;
}
.job-table th {
  font-size: 12px;
}

.job-select .form-select {
  font-size: 13px;
  font-weight: 400;
}

.comp-input-div .label {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
}

.modal-dialog .job-modal {
  margin-top: 68px !important;
  width: 1300px !important;
  position: absolute;
  left: -32%;
  top: -65px;
  height: 600px;
  overflow-y: scroll;
}
.job-modal .modal-header {
  height: 20px;
  background: #dc3545;
  font-size: 15px;
}
.job-modal .modal-title {
  font-size: 15px;
}
.job-select {
  padding: 10px 10px 10px 10px;
}
.job-select input {
  font-size: 13px;
  font-weight: 500;
}

.job-select-row {
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
}
.sync-data {
  width: 97px;
  border: 0.5px solid grey;
  border-radius: 5px;
  padding: 0px 6px;
  font-size: 13px;
  color: grey;
}

/* // Template Modal CSS************************************************************************** */

.modal-dialog .main-modal {
  margin-top: 68px;
  width: 1010px;
  position: absolute;
  left: -40%;
  height: 530px;
  overflow-y: scroll;
}

.modal-header {
  background: #1da586;
  color: #fff;
}

.reciepts-modal {
  display: flex;
  align-items: baseline;
  grid-gap: 20px;
  gap: 20px;
}
.custom-modal-btn {
  border: 0.5px solid rgb(116, 116, 116);
  background-color: #1da586;
  padding: 5px 13px;
  margin-top: 25px;
  color: white;
}
.modal-save {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(116, 116, 116);
}

.pmsg {
  background: rgb(245 245 245);
  margin: 0px !important;
  padding: 5px 2px;
}
.pmsg-para p {
  font-size: 16px;
  color: gray;
}

.modal-dialog .bulk-modal {
  margin-top: 68px;
  width: 1169px;
  position: absolute;
  left: -60%;
  height: 536px;
  overflow-y: scroll;
}
.modal-dialog .customize-modal {
  margin-top: 68px;
  width: 1010px;
  position: absolute;
  left: -40%;
  height: 530px;
  overflow-y: scroll;
}

.bulkupload {
  padding: 130px;
}

.bulk-close:hover {
  background-color: #1da586;
  color: #fff;
}

/* // Job Portal Css ****************************************************************/

/* //Sidebar ********************************************************* */
.sidebar {
  width: 60px;
  min-height: 100vh;
  background-color: #fff;
  border-right: 0.3px solid #e3e3e3;
  transition: width 0.3s ease-in-out;
}

/* .expanded {

} */

.sidebar:hover {
  width: 240px;
}
.sidebar:hover .logo-tab {
  display: block;
}

.sidebar-span {
  display: flex;
  /* gap: 2px; */
}

/* .collapsed {
  
} */
.sidebar .avatar-menu img {
  width: 40px;
  transition: 0.5s all;
  margin-top: 30px;
}
.sidebar:hover .avatar-menu img {
  width: 60px;
  transition: 0.5s all;
  margin-top: 30px;
}
.sidebar .avatar-menu p {
  display: none;
}
.sidebar:hover .avatar-menu p {
  display: block;
}
.sidebar .avatar-menu h6 {
  display: none;
}
.sidebar:hover .avatar-menu h6 {
  display: block;
}
.sidebar .sidebar-logo {
  display: none;
  /* transition: 0.5s all; */
}
.sidebar:hover .sidebar-logo {
  display: block;
  transition: 0.5s all;
}
.sidebar .new-sidebar-list {
  padding: 15px;
}
.sidebar .new-sidebar-list a {
  display: none;
  transition: 0.5s all;
}
.sidebar .new-sidebar-list li {
  padding: 17px 5px;
}
.sidebar .nav-tab-heading {
  display: none;
}
.toggle-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 37px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}
.side-button {
  display: flex;
  justify-content: end;
  height: 54px;
}
.sidebar-logo img {
  transition: 0.5s all;
  width: 150px;
}

.avatar-menu img {
  width: 83px;
  border-radius: 50px;
  transition: 0.5s all;
}
.avatar-menu p {
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;
}
.avatar-menu h6 {
  font-weight: 500;
  font-size: 13px;
}
.new-sidebar-list li {
  list-style-type: none;
  color: #3e4b5b;
  padding: 9px 5px;
  display: flex;
  align-items: baseline;
}
.new-sidebar-list .fa-solid {
  margin-right: 10px;
  color: #6a6b83;
  font-size: 18px;
}

.new-sidebar-list .fa-solid:hover {
  color: #0056b3;
}

.new-sidebar-list a {
  color: #6a6b83;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  transition: 0.5s all;
}
.sidebar .new-sidebar-list .logo-link {
  display: block !important;
}

.new-sidebar-list a:hover {
  cursor: pointer;
}
.nav-tab-heading {
  margin: 16px 0px;
}
.nav-tab-heading {
  margin-left: 37px;
  font-size: 20px;
  font-weight: 600;
}

/* //Horizontal Nav *********************************************** */

.new-nav {
  color: #fff;
  padding-left: 24px;
  padding-right: 16px;
  width: 100%;
  height: 54px;
  background: #0033c4;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new-nav span {
  font-weight: 500;
  font-family: "Roboto";
  font-size: 18px;
}
.pdf-options {
  display: block;
  position: relative;
  z-index: 9999;
  font-size: 12px;
  line-height: 25px !important;
  height: 25px !important;
  border: 1px solid #1da586;
  color: #000;
  width: 117px;
  margin-bottom: 7px;
}
.pdf-options-ul {
  -webkit-column-count: 2;
          column-count: 2;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.view-doc {
  display: block;
  -webkit-user-select: none;
          user-select: none;
  width: 142px;
  height: 154px;
  margin-left: 2px;
  border: 0.5px dashed black;
  margin-top: 10px;
  padding: 7px;
  border-radius: 10px;
}

.signin-container {
  height: 100vh;
  text-align: center;
}
.custom-select {
  width: 300px;
}

.dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}

.select-container {
  width: 400px;

  border: 1px solid #ccc;

  border-radius: 5px;

  overflow: hidden;
}

.select {
  width: 100%;

  padding: 10px;

  background-color: #f9f9f9;

  border: none;

  outline: none;

  cursor: pointer;

  font-weight: 500;
}

.options {
  max-height: 120px;

  overflow-y: auto;

  display: block;

  background-color: #fff;

  border: 1px solid #ccc;

  border-top: none;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option.selected {
  background-color: #f2f2f2;
  font-weight: 500;
}
.jobfilter {
  background-color: #dc3545 !important;
}

/* DateRangePicker.css */
/* .date-range-picker {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  max-width: 400px;
  margin: 0 auto;
} */

.react-datepicker {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
}

.react-datepicker__header {
  background-color: #007bff;
  color: white;
  border-radius: 4px 4px 0 0;
  padding: 8px 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #007bff;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #0056b3;
}

.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 5px;
}
.table-container {
  padding: 0 !important;
}

.select-job p {
  font-size: 15px;
  font-weight: 600;
  color: #dc3545;
  font-family: "circular";
  margin-right: 10px;
  margin-top: 2px;
}
.assign-modal .modal-header {
  background: #0033c4;
  height: 45px;
}
.assign-modal .modal-title {
  font-size: 18px;
}
.job-modal .modal-header .btn-close {
  -webkit-filter: invert(100%) !important;
          filter: invert(100%) !important;
  opacity: 1;
}
.assign-modal .modal-header .btn-close {
  -webkit-filter: invert(100%) !important;
          filter: invert(100%) !important;
  opacity: 1;
}
.select-job {
  font-size: 14px;
  font-weight: 600;
  word-spacing: 3px;
}

.btn-close {
  -webkit-filter: invert(1) !important;
          filter: invert(1) !important;
  opacity: 1 !important;
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }

  @media screen and (min-width: 1400px) {
    .container {
      max-width: 1650px !important;
    }
  }
}

.fa-filter {
  color: white;
  font-size: 16px;
  margin-right: 2px;
  margin-top: 2px;
}
.table-btn {
  border: none;
  background: #4285f4;
  color: white;
  border-radius: 5px;
  width: 70px;
  height: 25px;
}
.table-btn:hover {
  background: #2a6ad3;
  cursor: pointer;
}

.react-datalist-input__listbox {
  max-height: 300px;
  overflow: scroll;
}

.job-common-btn {
  background: #0033c4 !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
}

.job-id-span {
  background: #0033c43d !important;
  padding: 6px 13px;
  font-size: 15px !important;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

/* .portal-logout {
  float: right;
} */

#portal-login {
  background: none !important;
  box-shadow: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 17px;
}

#portal-login .dropdown-menu .show {
  position: absolute;
  inset: -11px -7px auto auto !important;
  -webkit-transform: translate3d(-6px, 58.5px, 0px);
          transform: translate3d(-6px, 58.5px, 0px);
}

.delete-job {
  border: none;
  background: none;
  cursor: pointer;
}
.delete-job .fa-trash {
  color: #000 !important;
}

.dash-head h3 {
  margin-bottom: 0px;
}

.dash-head span {
  color: gray;
  font-size: 16px;
  font-weight: 500;
}
.dashcard-data {
  color: #fff;
}

.dashboard-card {
  grid-gap: 30px;
  gap: 30px;
  padding: 10px;
}
.dashboard-card .fa-solid {
  font-size: 45px !important;
  color: #fff;
}

.dashcard-data span {
  font-size: 17px;
  font-weight: 400;
}
.dashcard-data p {
  font-size: 13px;
  font-weight: 500;
}
.dashcard-data h3 {
  font-size: 21px;
  font-weight: 600;
  margin: 5px 0px;
}

.first-card {
  background-image: linear-gradient(
    -20deg,
    #2b5876 0%,
    #4e4376 100%
  ) !important;
  border-radius: 7px;
}
.second-card {
  background-color: #0066b2;
  border-radius: 7px;
}
.third-card {
  background-image: linear-gradient(
    to top,
    #0ba360 0%,
    #3cba92 100%
  ) !important;
  border-radius: 7px;
}

.team-info h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.team-info span {
  color: #6a6b83;
  font-size: 14px;
  font-weight: 600;
}
.team-table {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  padding: 10px;
}
.ver-line {
  background: linear-gradient(#ff4500, #ff6347);
  height: 26px;
  width: 3px;
}
.dash-table-head {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.team-desig h6 {
  font-size: 15px;
  font-weight: 600;
  color: #6a6b83;
}
.team-table-data {
  padding: 0px 10px;
}

/* //Loader CSS */

.loader {
  display: block;
  z-index: 99;
  height: 100vh;
  /* margin-top: 10vh; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
  width: 100px;
  height: 100px;
  border: 3px solid #dc3545;
  border-radius: 50%;

  -webkit-animation: spin 7s ease-in-out;

          animation: spin 7s ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  transition-duration: 0.1s;
}

.loader:hover {
  scale: 0.95;
  /*Loader on hover effect*/
}

.loader:active {
  scale: 1.2;
  /*Loader on click effect*/
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-bottom: solid 3px transparent;
    border-top: solid 3px transparent;
  }
  50% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
    border: 3px solid #dc3545;
    border-left: solid 3px transparent;
    border-right: solid 3px transparent;
  }
  100% {
    /*Reversed spinning*/
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-bottom: solid 3px transparent;
    border-top: solid 3px transparent;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-bottom: solid 3px transparent;
    border-top: solid 3px transparent;
  }
  50% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
    border: 3px solid #dc3545;
    border-left: solid 3px transparent;
    border-right: solid 3px transparent;
  }
  100% {
    /*Reversed spinning*/
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-bottom: solid 3px transparent;
    border-top: solid 3px transparent;
  }
}

/* //Material Table Height ****************************   */
.css-nhjqqh-MuiTableContainer-root {
  max-height: 67vh;
  overflow: scroll;
}

/* Bill Calculator ****************************************************** */
.bill-calculator .modal-header {
  background: #0033c4;
  height: 45px;
}
.bill-calculator .modal-title {
  font-size: 18px;
}
.bill-calculator .modal-header .btn-close {
  -webkit-filter: invert(100%) !important;
          filter: invert(100%) !important;
  opacity: 1;
}

.calculator-row {
  border: 0.5px solid #d5d5d5;
  border-radius: 4px;
  padding: 20px;
  grid-gap: 20px;
  gap: 20px;
}
/* .input-div {
  border-right: 0.5px solid #d5d5d5;
} */

.calculator-row {
  margin-top: 20px;
  background-color: #f8f9fa;
  margin-bottom: 20px;
}

.ss-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ss-btn:hover {
  background-color: #0056b3;
}

.download-buttons {
  display: flex;
  justify-content: end;
  grid-gap: 10px;
  gap: 10px;
  margin-right: 25px;
}
.export-btn {
  background-color: #ff7b00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.export-btn:hover {
  background-color: #ffae00;
}

.fa-download {
  color: #fff;
}
.chip span {
  border: 1px dashed whitesmoke; 
}

.bill-table td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.bill-table td{
  background-color: #fff;
}

.bill-table tr td:first-child {
  width: 200px;
}

.table-heading{
  padding: 0;
    margin: 0;
    background: #0064ff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.table-heading h3{
  font-size: 17px;
  color: #fff;
  padding: 5px 0px;
  font-weight: 600;
}
.input-div{
  background-color: #fff;
  border-radius: 8px;
}
.output-table td {
  padding: 11px 10px;
}
.label_display {
  display: none;
}
